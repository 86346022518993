/* src/styles.css */
.App {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .purchase-list {
    display: flex;
    flex-direction: column;
  }
  
  .purchase-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: #f3f3f3;
    margin-left: 10px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
  }
  